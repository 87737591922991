// ==========================================================================
// Product List Banner Widget Royal Slider JS Module
// ==========================================================================


define(['jquery'], function($) {

  

  var ProductSkuWidget = function() {

    var rsInstance = []; // For royal slider plus AMD

    var self = this;

    var defaultConfig = {
      sliderDrag: true,
      sliderTouch: true,
      transitionType: 'move',
      arrowsNav: true,
      arrowsNavAutoHide: false,
      controlNavigation: 'none',
      keyboardNavEnabled: true,
      allowCSS3: true,
      loop: true,
      autoPlay: {
        enabled: true,
        pauseOnHover: true,
        delay: 3000
      }
    };

    function init(config) {
      var $carouselSet = $('.product-list .royal-slider');

      if (!config) {
        config = defaultConfig;
      }

      $carouselSet.each(function(i) {
        // is there more than one child
        var cCount = $(this).children().length;
        if (cCount > 1) {
          var rs = $(this).royalSlider(config);
          if (rs) {
            rsInstance[i] = {
              'find': $(this),
              'data': rs.data('royalSlider')
            };
          }
        }
      });
    }

    self.init = init;
  };

  var productSkuWidget = new ProductSkuWidget();

  return productSkuWidget;
});
